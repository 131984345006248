import * as React from 'react'
import classnames from 'classnames'

import { row, reversed } from './Row.module.scss'

interface RowProps extends React.HTMLProps<HTMLDivElement> {
  reverse?: boolean
}

export const Row: React.SFC<RowProps> = ({ style, reverse, children }) => (
  <div className={classnames(row, reverse ? reversed : '')} style={style}>
    {children}
  </div>
)

export default Row
