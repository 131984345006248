import * as React from 'react'
import classnames from 'classnames'

import { Link as ScrollLink } from 'react-scroll'
import { FaInstagram,
         FaLinkedinIn,
	 FaGithub,
	 FaEnvelope } from 'react-icons/fa'

import { Container } from '../Container'
import { UnsplashCreditBadge } from '../UnsplashCreditBadge'
import MeSquare from './mesquare.jpg'

import { Row } from '../Row'
import { Col } from '../Col'
import { Href } from '../Href'

import { intro, introInner, alternate, rule, credit } from './Intro.module.scss'

export const Intro = () => (
  <div className={intro}>
    <div className={introInner}>
      <Container>
                        <h1>Hey, how's it going?</h1>
      </Container>
    </div>
    <div className={credit}>
      <UnsplashCreditBadge
        src="https://unsplash.com/@charlesfair?utm_medium=referral&amp;utm_campaign=photographer-credit&amp;utm_content=creditBadge"
        author="Charles Fair"
      />
    </div>
    <Container>
    <Row style={{ paddingTop: '4rem', paddingBottom: '4rem' }}>
    <Col>
    <div className={alternate}>
    	<p>I'm Josh Kahlenberg. Computer software has long been my interest, particularly that which captures a sense of wonder and social purpose.
       	I am currently living in and loving Austin, TX.
   	 </p>
 	   <p>For those interested, my last name is pronounced with the &quot;short 	a&quot; sound. Think &quot;Kallenberg.&quot;
	   </p>
    	<p>One thing I quite enjoy is working on (and occasionally riding!) my Honda
       	{' '}<a target="_blank" href="http://pages.cs.wisc.edu/~john/vfr/specs/94-97-vfr750f.html">motorcycle.</a>
       	{' '}Another interest is taking snapshots. If you're curious, check out my 
       	{' '}<a target="_blank" href="https://www.instagram.com/jkahlenberg/"><FaInstagram /> Instagram.</a>
       	{' '}I live in a <a target="_blank" href="https://collegehouses.org/what-is-a-co-op/">co-op.</a>
    	</p>
    </div>
    </Col>
    <div>
     <div className={alternate}>
     <img alt="myself" src={MeSquare} height="201px" width="201px" />
      <p className={rule} />
      <a target="_blank" href="https://www.linkedin.com/in/lnked/"><FaLinkedinIn /> Joshua Kahlenberg</a>
      <br /><a target="_blank" href="https://github.com/dadanyet/"><FaGithub /> dadanyet</a>
      <br /><a href="mailto:"><FaEnvelope /> jbk codehop net</a>
      </div>
    </div>
    </Row>
  </Container>
  </div>
)
