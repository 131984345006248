import * as React from 'react'

import { Container } from '../Container'

import { footer } from './Footer.module.scss'
import { footerRule } from './Footer.module.scss'

export const Footer = () => (
  <footer className={footer}>
    <Container>
      <hr className={footerRule} />
      <p>
        <small>
          <span>©2020, 2021 Joshua Kahlenberg</span>
          . Images from{' '}
          <a href="https://unsplash.com/" target="_blank" rel="noopener noreferrer">
            Unsplash
          </a>
          . Built with{' '}
          <a href="https://www.gatsbyjs.org/" target="_blank" rel="noopener noreferrer">
            Gatsby
          </a>
          . Further credit to {' '}
          <a href ="https://resir014.xyz/" target="_blank" rel="noopener noreferrer">
            Resi
          </a>
          .
        </small>
      </p>
    </Container>
  <script>var clicky_site_ids = clicky_site_ids || []; clicky_site_ids.push(101319802);</script>
  <script async src="//static.getclicky.com/js"></script>
  <noscript><p><img alt="Clicky" width="1" height="1" src="//in.getclicky.com/101319802ns.gif" /></p></noscript>
  </footer>
)
